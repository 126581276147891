<template>
  <v-container
    id="payments-list"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-currency-usd"
      title="Transactions"
      class="px-5 py-3"
    >
      <!-- Recherche (Seulement pour la route 'payments-transaction-list')-->
      <div
        class="d-flex align-center justify-center"
      >
        <v-slide-group
          v-model="model"
          class="pa-4"
          center-active
          show-arrows
        >
          <v-slide-item
            v-for="(item, i) in searchCard"
            :key="i"
            v-slot="{ active, toggle }"
          >
            <v-card
              class="ma-4"
              rounded="lg"
              outlined
              :style="{ border: active ? '1px solid #c7254e' : null }"
              height="100"
              width="170"
              @click="toggle"
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
                <span :class="active ? 'primary--text' : 'grey--text'">{{ item.text }}</span>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </div>

      <validation-observer
        ref="obs"
      >
        <form>
          <!-- Recherche par Client -->
          <v-row
            v-if="model === 1"
            align="center"
            justify="center"
            dense
          >
            <v-col
              cols="8"
              class="text-body-1 grey--text"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Client"
                :rules="{ required: true, min: searchSelected.facture.minLength }"
              >
                <v-text-field
                  v-model="searchSelected.facture.selected"
                  class="mt-3"
                  :label="$t('Payment.placeHolder.bill')"
                  :error-messages="errors"
                  :success="valid"
                  required
                  @keydown.enter.prevent
                  @keydown.enter="submitSearch"
                />
              </validation-provider>
            </v-col>
          </v-row>

          <!-- Recherche par Période -->
          <v-row
            v-if="model === 2"
            align="center"
            justify="center"
            dense
          >
            <v-col
              cols="8"
              class="text-body-1 grey--text"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                :name="$t('time.month')"
                :rules="{ required: true }"
              >
                <v-select
                  v-model="searchSelected.period.selected"
                  :items="periods"
                  :label="$t('tools.billing.Period')"
                  :error-messages="errors"
                  :success="valid"
                  required
                  @change="submitSearch"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </form>
      </validation-observer>

      <payments-list-stripe-component
        ref="paymentsListStripeComponent"
        :data-transactions="dataTransactions"
        :per-page="25"
        @update-list-transactions="updateListTransactions"
        @load-more="loadMore"
      />
    </base-material-card>
  </v-container>
</template>

<script>
  // Component
  import PaymentsListStripeComponent from '@/components/Payments/Stripe/PaymentsListComponent'
  // Service
  import StripeChargeService from '@/services/STRIPE/StripeChargeService'
  import FacturesService from '@/services/01Cell/FacturesService'
  // Mixins
  import { stripe } from '@/mixins/Payment/stripe'
  // Other
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'

  export default {
    name: 'PaymentsTransactionList',
    components: {
      PaymentsListStripeComponent,
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [stripe],
    data () {
      return {
        model: 0,
        periods: [],
        searchCard: [
          { text: this.$i18n.t('Payment.searchTransactions.All_transactions') },
          { text: this.$i18n.t('Payment.searchTransactions.Search_by_billing') },
          { text: this.$i18n.t('tools.billing.Period') },
        ],
        searchSelected: {
          facture: {
            selected: null,
            minLength: 1,
          },
          period: {
            selected: null,
          },
        },
        dataTransactions: {
          data: [],
          has_more: false,
          startingAfter: null,
          next_page: null,
        },
      }
    },

    computed: {
    },

    watch: {
      model (value) {
        this.submitSearch()
      },
    },

    mounted () {
      this.getListTransactions()
      FacturesService.getPeriode().then(response => {
        this.periods = response
      })
    },

    methods: {
      reset () {
        // Reset
        this.dataTransactions.data = []
        this.dataTransactions.has_more = false
        this.dataTransactions.startingAfter = null
        this.dataTransactions.next_page = null
      },
      submitSearch () {
        this.reset()
        this.search()
      },
      search () {
        if (this.model === 0) this.getListTransactions()
        if (this.model === 1) this.getListTransactionsByFactureId()
        if (this.model === 2) this.getListTransactionsByPeriode()
      },
      async getListTransactions () {
        // Start Spinner
        this.$loading.show()
        try {
          const charges = await StripeChargeService.list(this.dataTransactions.startingAfter)
          this.manipulation(charges)

          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async getListTransactionsByFactureId () {
        if (!this.searchSelected.facture.selected) return
        // Min 3 charactères
        if (this.searchSelected.facture.selected.length < this.searchSelected.facture.minLength) return

        // Start Spinner
        this.$loading.show()
        try {
          const charges = await StripeChargeService.queryByFactureId(this.searchSelected.facture.selected)
          this.manipulation(charges)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async getListTransactionsByPeriode () {
        if (!this.searchSelected.period.selected) return
        // Start Spinner
        this.$loading.show()
        try {
          const charges = await StripeChargeService.queryByPeriod(this.searchSelected.period.selected, this.dataTransactions.next_page)
          this.manipulation(charges)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      loadMore () {
        this.search()
      },
      async updateListTransactions (chargeId) {
        try {
          const findIndex = this.dataTransactions.data.findIndex(item => item.id === chargeId)
          const charge = await StripeChargeService.view(chargeId)

          // Update du paiement_status
          this.dataTransactions.data[findIndex].paiement_statut = charge.paiement_statut

          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
