<template>
  <v-container
    id="payments-account"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-currency-usd"
      :title="`${$t('tools.Account').toUpperCase()}`"
      class="px-5 py-3"
    >
      <template v-if="loading">
        <!-- Un compte de configuré -->
        <template v-if="account">
          <!--  Statut-->
          <v-row
            dense
            class="mt-4"
          >
            <v-col
              class="text-left text-body-1 ml-5"
              cols="2"
            >
              <span>{{ $t('Status') }}</span>
              <div>
                <span
                  v-if="accountStatus.requirements.length"
                  class="warning--text"
                >
                  <v-icon
                    class="warning--text"
                    left
                  > mdi-alert </v-icon>
                  <a
                    href=""
                    @click.prevent="accountLink"
                  >{{ $t('Action_required') }}</a>
                </span>
                <span
                  v-else
                  class="green--text"
                >{{ $t('Functional') }}</span>
              </div>
            </v-col>
          </v-row>

          <base-subheading />
          <base-material-tabs color="warning">
            <v-tab
              v-for="(tab, i) in tabs"
              :key="i"
            >
              {{ tab }}
            </v-tab>
            <v-tab-item>
              <form>
                <v-subheader class="text-h4 mt-5">
                  {{ $t('Payment.Company_profile') }}
                </v-subheader>
                <!--  Nom du compte-->
                <v-row dense>
                  <v-col
                    class="text-right text-body-1 grey--text mr-4"
                    cols="2"
                  >
                    <span>{{ $t('Payment.Company_name') }}:</span>
                  </v-col>
                  <v-col cols="8">
                    <span>{{ account.settings.dashboard.display_name }}</span>
                  </v-col>
                </v-row>
                <!-- Adresse de l'entreprise -->
                <v-row
                  v-if="account.business_profile.support_address"
                  align="center"
                  dense
                >
                  <v-col
                    class="text-right text-body-1 grey--text mr-4"
                    cols="2"
                  >
                    <span>{{ $t('Payment.Company_address') }}:</span>
                  </v-col>
                  <v-col cols="8">
                    <div class="mt-5">
                      <!-- Adresse 1 -->
                      <span>{{ account.business_profile.support_address.line1 }}</span>
                    </div>
                    <!-- Adresse 2 -->
                    <div>
                      <span>{{ account.business_profile.support_address.line2 }}</span>
                    </div>
                    <!-- Ville -->
                    <div>
                      <span>{{ account.business_profile.support_address.city }}</span>
                    </div>
                    <!-- Province -->
                    <div>
                      <span>{{ provinces[account.business_profile.support_address.state] }}</span>
                    </div>
                    <!-- Code postale -->
                    <div>
                      <span>{{ account.business_profile.support_address.postal_code }}</span>
                    </div>
                  </v-col>
                </v-row>
                <!--  Téléphone-->
                <v-row
                  align="center"
                  class="mt-3"
                  dense
                >
                  <v-col
                    class="text-right text-body-1 grey--text mr-4"
                    cols="2"
                  >
                    <span>{{ $t('Payment.Support_phone') }}:</span>
                  </v-col>
                  <v-col cols="8">
                    <span>{{ account.business_profile.support_phone }}</span>
                  </v-col>
                </v-row>
                <!--  URL d'assistance-->
                <v-row
                  align="center"
                  class="mt-3"
                  dense
                >
                  <v-col
                    class="text-right text-body-1 grey--text mr-4"
                    cols="2"
                  >
                    <span>{{ $t('Payment.Support_url') }}:</span>
                  </v-col>
                  <v-col cols="8">
                    <span>{{ account.business_profile.support_url }}</span>
                  </v-col>
                </v-row>
                <!--  URL d'assistance-->
                <v-row
                  align="center"
                  class="mt-3"
                  dense
                >
                  <v-col
                    class="text-right text-body-1 grey--text mr-4"
                    cols="2"
                  >
                    <span>URL:</span>
                  </v-col>
                  <v-col cols="8">
                    <span>{{ account.business_profile.url }}</span>
                  </v-col>
                </v-row>
                <!--  Fuseau horaire-->
                <v-row
                  align="center"
                  class="mt-3"
                  dense
                >
                  <v-col
                    class="text-right text-body-1 grey--text mr-4"
                    cols="2"
                  >
                    <span>{{ $t('Payment.Time_zone') }}:</span>
                  </v-col>
                  <v-col cols="8">
                    <span>{{ account.settings.dashboard.timezone }}</span>
                  </v-col>
                </v-row>
              </form>
            </v-tab-item>
            <v-tab-item>
              <v-subheader class="text-h4 mt-5">
                {{ $t('Details') }}
              </v-subheader>
              <!--  Statut-->
              <v-row dense>
                <v-col
                  class="text-left text-body-1 ml-5"
                  cols="2"
                >
                  <span>{{ $t('Status') }}</span>
                  <div>
                    <span
                      v-if="accountStatus.requirements.length"
                      class="warning--text"
                    >
                      <v-icon
                        class="warning--text"
                        left
                      > mdi-alert </v-icon>
                      <a
                        href=""
                        @click.prevent="accountLink"
                      >{{ $t('Action_required') }}</a>
                    </span>
                    <span
                      v-else
                      class="green--text"
                    >{{ $t('Functional') }}</span>
                  </div>
                </v-col>
              </v-row>
              <!--  Impact-->
              <v-subheader class="text-h4 mt-5">
                {{ $t('Impact') }}
              </v-subheader>
              <!-- Paiement -->
              <v-row dense>
                <v-col
                  class="text-left text-body-1 ml-5"
                  cols="2"
                >
                  <span>{{ $t('sidebar.payments') }}</span>
                  <div>
                    <span
                      v-if="accountStatus.chargesEnabled"
                      class="warning--text"
                    >
                      <v-icon
                        class="warning--text"
                        left
                      > mdi-alert </v-icon>
                      <a
                        href=""
                        @click.prevent="accountLink"
                      >{{ $t('Action_required') }}</a>
                    </span>
                    <span
                      v-else
                      class="green--text"
                    >{{ $t('Functional') }}</span>
                  </div>
                </v-col>
              </v-row>
              <!-- Virement -->
              <v-row dense>
                <v-col
                  class="text-left text-body-1 ml-5"
                  cols="2"
                >
                  <span>{{ $t('Payment.Payouts') }}</span>
                  <div>
                    <span
                      v-if="accountStatus.payoutsEnabled"
                      class="warning--text"
                    >
                      <v-icon
                        class="warning--text"
                        left
                      > mdi-alert </v-icon>{{ $t('Action_required') }}
                    </span>
                    <span
                      v-else
                      class="green--text"
                    >{{ $t('Functional') }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <!-- Compte bancaire -->
              <v-card
                outlined
                shaped
              >
                <v-card-title>{{ $t('Payment.Bank_accounts') }}</v-card-title>
                <v-card-text>
                  <v-data-table
                    class="mt-5"
                    :headers="tableExternalBanksHeaders"
                    :items="account.external_accounts.data"
                  >
                    <!-- Compte -->
                    <template
                      slot="item.bank_name"
                      slot-scope="props"
                    >
                      <span><v-icon
                        left
                      >
                        mdi-bank
                      </v-icon>{{ props.item.bank_name }}</span>
                    </template>

                    <!-- Compte -->
                    <template
                      slot="item.last4"
                      slot-scope="props"
                    >
                      <span>....{{ props.item.last4 }} / {{ props.item.routing_number }}</span>
                    </template>

                    <!-- devise -->
                    <template
                      slot="item.currency"
                      slot-scope="props"
                    >
                      <span>{{ props.item.currency.toUpperCase() }}</span>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>

              <!-- Fréquence des virements -->
              <v-card
                outlined
                shaped
              >
                <v-card-title>{{ $t('Payment.Frequency_transfers') }}</v-card-title>
                <v-card-text class="grey--text">
                  <span>{{ $t('Payment.Frequency_transfers_message') }}</span>
                </v-card-text>

                <!--  Fréquence-->
                <validation-observer
                  ref="obs"
                  v-slot="{ handleSubmit }"
                >
                  <form>
                    <v-row
                      dense
                    >
                      <v-col
                        class="text-body-1 ml-4"
                        cols="8"
                      >
                        <v-radio-group v-model="payoutsSchedule">
                          <!-- Paiement automatique -->
                          <v-radio
                            value="automatique"
                            @change="changeInterval"
                          >
                            <template #label>
                              <!-- Manuel -->
                              <template v-if="account.settings.payouts.schedule.interval === 'manual'">
                                {{ $t('Automatic') }}
                              </template>
                              <!-- jour -->
                              <template v-if="account.settings.payouts.schedule.interval === 'daily'">
                                {{ $t('Payment.Automatic_every') }}

                                <!-- Interval -->
                                <validation-provider
                                  v-slot="{ errors, valid }"
                                  name="Interval"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="account.settings.payouts.schedule.interval"
                                    class="ml-3 mt-3"
                                    dense
                                    style="width: 100px;"
                                    :items="schedule"
                                    item-text="text"
                                    item-value="valeur"
                                    :error-messages="errors"
                                    :success="valid"
                                    required
                                    @change="changeInterval"
                                  />
                                </validation-provider>
                              </template>

                              <!-- Semaine -->
                              <template v-if="account.settings.payouts.schedule.interval === 'weekly'">
                                {{ $t('Payment.Automatic_every') }}

                                <!-- Interval -->
                                <validation-provider
                                  v-slot="{ errors, valid }"
                                  name="Interval"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="account.settings.payouts.schedule.interval"
                                    class="ml-3 mt-3"
                                    dense
                                    style="width: 100px;"
                                    :items="schedule"
                                    item-text="text"
                                    item-value="valeur"
                                    :error-messages="errors"
                                    :success="valid"
                                    required
                                    @change="changeInterval"
                                  />
                                </validation-provider>
                                <span class="ml-3">{{ $t('On').toLowerCase() }}</span>

                                <!-- Jour de semaine -->
                                <validation-provider
                                  v-slot="{ errors, valid }"
                                  :name="$t('time.day')"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="account.settings.payouts.schedule.weekly_anchor"
                                    class="ml-3 mt-3"
                                    dense
                                    style="width: 500px;"
                                    :items="scheduleWeek"
                                    item-text="text"
                                    item-value="valeur"
                                    :error-messages="errors"
                                    :success="valid"
                                    required
                                  />
                                </validation-provider>
                              </template>

                              <!-- Mois -->
                              <template v-if="account.settings.payouts.schedule.interval === 'monthly'">
                                {{ $t('Payment.Automatic_every') }}

                                <!-- Interval -->
                                <validation-provider
                                  v-slot="{ errors, valid }"
                                  name="Interval"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="account.settings.payouts.schedule.interval"
                                    class="ml-3 mt-3"
                                    dense
                                    style="width: 100px;"
                                    :items="schedule"
                                    item-text="text"
                                    item-value="valeur"
                                    :error-messages="errors"
                                    :success="valid"
                                    required
                                    @change="changeInterval"
                                  />
                                </validation-provider>
                                <span class="ml-3">{{ $t('On_the').toLowerCase() }}</span>

                                <!-- Jour dans le mois -->
                                <validation-provider
                                  v-slot="{ errors, valid }"
                                  :name="$t('time.day')"
                                  rules="required"
                                >
                                  <v-select
                                    v-model="account.settings.payouts.schedule.monthly_anchor"
                                    class="ml-3 mt-3"
                                    dense
                                    style="width: 500px;"
                                    :items="scheduleMonth"
                                    :error-messages="errors"
                                    :success="valid"
                                    required
                                  />
                                </validation-provider>
                              </template>
                            </template>
                          </v-radio>

                          <!-- Manuel -->
                          <v-radio value="manual">
                            <template #label>
                              <div>{{ $t('Payment.Manual_payouts') }}</div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <!-- Fréquance bouton -->
                    <v-row
                      dense
                    >
                      <v-col
                        class="text-body-1 ml-4"
                        cols="12"
                      >
                        <div class="pa-3 text-right mr-5">
                          <!--  Bouton annuler-->
                          <v-btn
                            small
                            color="error"
                            @click="getData"
                          >
                            {{ $t('Cancel') }}
                          </v-btn>
                          <!--  Bouton submit-->
                          <v-btn
                            small
                            color="success"
                            class="ml-3"
                            @click="handleSubmit(saveInterval)"
                          >
                            {{ $t('Validate') }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </form>
                </validation-observer>
              </v-card>
            </v-tab-item>
          </base-material-tabs>
          <div class="text-right">
            <v-btn
              class="mt-5"
              rounded
              color="green"
              dark
              @click="accountLink"
            >
              {{ $t('Payment.Add_info') }}
            </v-btn>
          </div>
        </template>
        <!-- Pas de compte -->
        <template v-else>
          <div class="text-center">
            <!-- Ajouter un compte -->
            <v-btn
              class="mt-5"
              rounded
              color="green"
              dark
              @click="addAccount"
            >
              {{ $t('payment.Add_account') }}
            </v-btn>
          </div>
        </template>
      </template>
    </base-material-card>
  </v-container>
</template>
<script>
  // Service
  import StripeAccountService from '@/services/STRIPE/StripeAccountService'
  // Other
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'

  export default {
    name: 'PaymentsAccount',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        loading: false,
        account: null,
        accountStatus: {
          chargesEnabled: false, // true si le compte peut traiter des paiements. false s'il reste des étapes de vérification.
          payoutsEnabled: false, // true si le compte peut recevoir des paiements. false s'il reste des étapes administratives.
          requirements: [],
        },
        payoutsSchedule: 'automatique', // manuel ou automatique
        tabs: [
          this.$i18n.t('Payment.Account_info'),
          this.$i18n.t('Payment.Account_status'),
          this.$i18n.t('Payment.Account_external'),
        ],
        provinces: {
          AB: this.$i18n.t('client.province.Alberta'),
          BC: this.$i18n.t('client.province.British_Columbia'),
          PE: this.$i18n.t('client.province.Prince_Edward_Island'),
          MB: this.$i18n.t('client.province.Manitoba'),
          NB: this.$i18n.t('client.province.New_Brunswick'),
          NS: this.$i18n.t('client.province.Nova_Scotia'),
          NU: this.$i18n.t('client.province.Nunavut'),
          ON: this.$i18n.t('client.province.Ontario'),
          QC: this.$i18n.t('client.province.Quebec'),
          SK: this.$i18n.t('client.province.Saskatchewan'),
          NL: this.$i18n.t('client.province.Newfoundland_and_Labrador'),
          NT: this.$i18n.t('client.province.Northwest_Territories'),
          YT: this.$i18n.t('client.province.Yukon'),
        },
        tableExternalBanksHeaders: [
          { text: this.$i18n.t('Payment.Bank'), value: 'bank_name', sortable: false, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Payment.Account_number'), value: 'last4', sortable: false, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('Payment.Currency'), value: 'currency', sortable: false, class: 'text-h4 primary--text' },
        ],
        schedule: [
          { text: this.$i18n.t('calendar.type.day'), valeur: 'daily' },
          { text: this.$i18n.t('calendar.type.week'), valeur: 'weekly' },
          { text: this.$i18n.t('calendar.type.month'), valeur: 'monthly' },
        ],
        scheduleWeek: [
          { text: this.$i18n.t('calendar.week_day.Monday'), valeur: 'monday' },
          { text: this.$i18n.t('calendar.week_day.Tuesday'), valeur: 'tuesday' },
          { text: this.$i18n.t('calendar.week_day.Wednesday'), valeur: 'wednesday' },
          { text: this.$i18n.t('calendar.week_day.Thursday'), valeur: 'thursday' },
          { text: this.$i18n.t('calendar.week_day.Friday'), valeur: 'friday' },
          { text: this.$i18n.t('calendar.week_day.Saturday'), valeur: 'saturday' },
          { text: this.$i18n.t('calendar.week_day.Sunday'), valeur: 'sunday' },
        ],
        scheduleMonth: Array.from({ length: 31 }, (_, i) => i + 1), // 1 à 31
      }
    },

    computed: {
    },

    mounted () {
      this.getData()
    },

    methods: {
      async getData () {
        // Start Spinner
        this.$loading.show()
        try {
          this.account = await StripeAccountService.view()

          if (this.account) {
            this.accountStatus = {
              chargesEnabled: this.account.charges_enabled,
              payoutsEnabled: this.account.payouts_enabled,
              requirements: this.account.requirements.currently_due || [],
            }
          }
          if (this.account.settings.payouts.schedule.interval === 'manual') this.payoutsSchedule = 'manual'
          this.loading = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async addAccount () {
        // Start Spinner
        this.$loading.show()
        try {
          // Création du compte et du accountLink pour compléter le compte chez Stripe
          const accountLink = await StripeAccountService.save()
          // Redirection vers Stripe
          window.location.href = accountLink.url
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },

      async accountLink () {
        // Start Spinner
        this.$loading.show()
        try {
          // AccountLink pour compléter le compte chez Stripe
          const accountLink = await StripeAccountService.createAccountLink({ accountId: this.account.id })
          // Redirection vers Stripe
          window.location.href = accountLink.url
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },

      changeInterval (event) {
        // Si undefined, on mets dailey
        if (!event) {
          this.account.settings.payouts.schedule.interval = 'daily'
        }

        if (event === 'weekly') {
          this.account.settings.payouts.schedule.weekly_anchor = 'monday'
        }

        if (event === 'monthly') {
          this.account.settings.payouts.schedule.monthly_anchor = 1
        }
      },

      async saveInterval () {
        // Start Spinner
        this.$loading.show()
        try {
          const data = {
            settings: {
              payouts: {
                schedule: {
                  interval: this.account.settings.payouts.schedule.interval,
                },
              },
            },
          }
          if (this.account.settings.payouts.schedule.interval === 'weekly') {
            data.settings.payouts.schedule.weekly_anchor = this.account.settings.payouts.schedule.weekly_anchor
          } else if (this.account.settings.payouts.schedule.interval === 'monthly') {
            data.settings.payouts.schedule.monthly_anchor = this.account.settings.payouts.schedule.monthly_anchor
          }

          this.account = await StripeAccountService.update(data)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
