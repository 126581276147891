<template>
  <v-container
    id="payments-list"
    fluid
    tag="section"
  >
    <v-breadcrumbs
      class="text-h4"
      :items="breadcrumbsItems"
      divider="/"
    >
      <template #item="{ item }">
        <span v-if="item.disabled">
          {{ item.text }}
        </span>
        <router-link
          v-else
          :to="{ name: item.to }"
          class="text-decoration-none"
        >
          {{ item.text }}
        </router-link>
      </template>
    </v-breadcrumbs>

    <base-material-card
      inline
      icon="mdi-account"
      :title="customer.name ? `${customer.name} (${customer.email})`: ''"
      class="px-5 py-3"
    >
      <v-row>
        <!-- Cartes -->
        <v-col cols="9">
          <h1>{{ $t('Payment.Payment_methods') }}</h1>
          <hr>
          <v-data-table
            ref="cardsTable"
            class="mt-5"
            :headers="headersCustomerCards"
            :items="customerCards"
            hide-default-header
            hide-default-footer
          >
            <!-- Brand -->
            <template
              slot="item.card.display_brand"
              slot-scope="props"
            >
              <v-row>
                <v-img
                  :src="require(`@/assets/card/${props.item.card.brand}.png`)"
                  max-width="50"
                  max-height="50"
                  class="mr-2"
                />
                <span style="margin-left: 16px;">{{ props.item.card.display_brand }}</span>
              </v-row>
            </template>

            <!-- Expiration -->
            <template
              slot="item.card.exp_year"
              slot-scope="props"
            >
              <span>{{ getMonth(props.item.card.exp_month) }} {{ props.item.card.exp_year }}</span>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="3">
          <!-- INFORMATION-->
          <template v-if="dataTransactions.data.length">
            <h1>Information</h1>
            <hr>
            <div class="card-info mt-2">
              <h3 class="font-weight m-0">
                {{ $t('Payment.Total_spend') }}
              </h3>
              <p class="font-weight-light my-1">
                {{ totalSpend }}
              </p>
              <p class="text--disabled m-0">
                {{ totalTransactionsSuccess.length }} transactions
              </p>
            </div>

            <div class="card-info mt-2">
              <h3 class="font-weight m-0">
                {{ $t('Refund') }}s
              </h3>
              <p class="font-weight-light my-1">
                {{ totalRefunded }}
              </p>
            </div>
          </template>

          <!-- CLIENT INFORMATION -->
          <h1 class="mt-5">
            {{ $t('Details') }}
          </h1>
          <hr>
          <!-- Customer ID -->
          <div class="card-info mt-2">
            <h3 class="font-weight m-0">
              {{ $t('Payment.Customer_ID') }}
            </h3>
            <p class="font-weight-light my-1">
              {{ customer.id }}
            </p>
          </div>

          <!-- Customer since -->
          <div class="card-info mt-2">
            <h3 class="font-weight m-0">
              {{ $t('Payment.Customer_since') }}
            </h3>
            <p class="font-weight-light my-1">
              {{ getCustomerSince }}
            </p>
          </div>

          <!-- Billing emails -->
          <div class="card-info mt-2">
            <h3 class="font-weight m-0">
              {{ $t('client.Email') }}
            </h3>
            <p class="font-weight-light my-1">
              {{ customer.email }}
            </p>
          </div>

          <!-- METADATA -->
          <h1 class="mt-5">
            {{ $t('Payment.Metadata') }}
          </h1>
          <hr>
          <!-- Customer ID -->
          <div class="card-info mt-2">
            <h3 class="font-weight m-0">
              {{ $t('Payment.Customer_ID') }} 01CELL
            </h3>
            <p class="font-weight-light my-1">
              <a
                href="#"
                @click.prevent="getClientId(customer.metadata.clientId)"
              >{{ customer.metadata.clientId }}</a>
            </p>
          </div>

          <!-- Customer Username -->
          <div class="card-info mt-2">
            <h3 class="font-weight m-0">
              {{ $t('Username') }} 01CELL
            </h3>
            <p class="font-weight-light my-1">
              {{ customer.metadata.username }}
            </p>
          </div>
        </v-col>
      </v-row>

      <!-- Transactions -->
      <v-row>
        <v-col cols="12">
          <h1>{{ $t('sidebar.payments') }}</h1>
          <hr>
          <payments-list-stripe-component
            ref="paymentsListStripeComponent"
            :data-transactions="dataTransactions"
            :per-page="10"
            @update-list-transactions="updateListTransactions"
            @load-more="loadMore"
          />
        </v-col>
      </v-row>
    </base-material-card>

    <!-- Edition du client -->
    <v-dialog
      v-model="dialogClient"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogClient = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-account
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <h3>Client</h3>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!--  Edition d'un client-->
        <div class="mt-5">
          <client-create-edit
            :create-edit-info="createEditInfo"
            :client-id="clientId"
            @create-update-client="createUpdateClient"
            @cancel-edit-client="cancelEditClient"
          />
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  // Components
  import ClientCreateEdit from '@/components/Client/ClientCreateEdit'
  import PaymentsListStripeComponent from '@/components/Payments/Stripe/PaymentsListComponent'
  // Service
  import StripeChargeService from '@/services/STRIPE/StripeChargeService'
  import StripeCustomerService from '@/services/STRIPE/StripeCustomerService'
  import ClientsService from '@/services/01Cell/ClientsService'
  // Mixins
  import { stripe } from '@/mixins/Payment/stripe'
  // Other
  import { format, setMonth } from 'date-fns'
  import { fr, enUS } from 'date-fns/esm/locale'
  window.dateFnsLocales = {
    fr,
    en: enUS,
  }

  export default {
    name: 'PaymentsCustomerTransactionList',
    components: {
      ClientCreateEdit,
      PaymentsListStripeComponent,
    },
    mixins: [stripe],
    data () {
      return {
        language: this.$i18n.t('Language'),
        totalSpend: '$0.00',
        totalRefunded: '$0.00',
        totalTransactionsSuccess: 0,
        customer: {
          id: null,
          name: null,
          email: null,
          created: null,
          metadata: {
            clientId: null,
            username: null,
          },
        },
        dataTransactions: {
          data: [],
          has_more: false,
          next_page: null,
        },
        breadcrumbsItems: [
          {
            text: `${this.$i18n.t('Customer')}s`,
            disabled: false,
            to: 'payments-customer-list',
          },
          {
            text: null,
            disabled: true,
            to: null,
          },
        ],
        customerCards: [],
        headersCustomerCards: [
          { text: null, value: 'card.display_brand', sortable: true, class: 'text-h4 primary--text' },
          { text: null, value: 'card.exp_year', sortable: true, class: 'text-h4 primary--text', align: 'center' },
        ],
        clientId: null,
        createEditInfo: {
          icon: 'mdi-pencil',
          title: `${this.$i18n.t('Edit')}`,
        },
        dialogClient: false,
      }
    },

    computed: {
      getCustomerSince () {
        if (!this.customer.created) return null
        const timestamp = this.customer.created * 1000
        return format(new Date(timestamp), 'd MMMM yyyy', { locale: window.dateFnsLocales[this.language] })
      },
    },

    async mounted () {
      this.$loading.show()
      try {
        // Info sure le customer
        const customerPromise = this.getCustomer()
        // Les cartes du customer
        const customerCardPromise = this.getCustomerCard()
        // Les trnasctions du customer
        const transactionsPromise = this.getListTransactions(false)
        await Promise.all([transactionsPromise, customerCardPromise, customerPromise])
        this.$loading.hide()
      } catch (error) {
        console.log(error)
        // Stop Spinner
        this.$loading.hide()
      }
    },

    methods: {
      getClientId (clientId) {
        this.clientId = parseInt(clientId, 10)
        this.dialogClient = true
      },
      getMonth (monthNumber) {
        const date = setMonth(new Date(), monthNumber - 1)
        const monthName = format(date, 'LLLL', { locale: window.dateFnsLocales[this.language] })
        return monthName.charAt(0).toUpperCase() + monthName.slice(1)
      },
      async getCustomer () {
        try {
          this.customer = await StripeCustomerService.view(this.$route.params.id)
          this.breadcrumbsItems[1].text = this.customer.name
        } catch (error) {
          console.log(error)
        }
      },
      async getCustomerCard () {
        try {
          const customerCards = await StripeCustomerService.card(this.$route.params.id)
          this.customerCards = customerCards.map(item => {
            item.card.display_brand = item.card.display_brand.split('_') // Divise la chaîne par le caractère '_'
              .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Met la première lettre de chaque mot en majuscule
              .join(' ') // Recombine les mots avec un espace entre eux
            return item
          })
        } catch (error) {
          console.log(error)
        }
      },
      async getListTransactions (loading) {
        // Start Spinner
        if (loading) this.$loading.show()
        try {
          const charges = await StripeChargeService.queryAllByCustomerId(this.$route.params.id, this.dataTransactions.next_page)
          this.manipulation(charges)

          let totalChargedAmount = 0
          let totalRefundedAmount = 0
          for (const charge of charges.data) {
            if (charge.status === 'succeeded') {
              // Si la charge est complètement remboursée
              totalChargedAmount += charge.amount
            }
            totalRefundedAmount += charge.amount_refunded
          }
          this.totalRefunded = '$' + (totalRefundedAmount / 100)
          this.totalSpend = '$' + (totalChargedAmount - totalRefundedAmount) / 100
          this.totalTransactionsSuccess = charges.data.filter(charge => charge.status === 'succeeded')
          // Stop Spinner
          if (loading) this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      loadMore () {
        this.getListTransactions(true)
      },
      async updateListTransactions (chargeId) {
        try {
          const findIndex = this.dataTransactions.data.findIndex(item => item.id === chargeId)
          const charge = await StripeChargeService.view(chargeId)

          // Update du paiement_status
          this.dataTransactions.data[findIndex].paiement_statut = charge.paiement_statut

          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async createUpdateClient (event) {
        // Start Spinner
        this.$loading.show()
        try {
          await ClientsService.update(event, event.id)

          // Montre la confirmation
          this.$notifier.show({ message: this.$i18n.t('client.snackBar.client_updated_successfully'), color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })

          // Close dialog
          this.dialogClient = false
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      cancelEditClient () {
        // Close dialog
        this.dialogClient = false
      },
    },
  }
</script>
