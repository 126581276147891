<template>
  <v-container
    id="operation-parameters"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-cog-outline"
      :title="`${$t('sidebar.adminClients').toUpperCase()}`"
      class="px-5 py-3"
    >
      <v-col
        cols="12"
        sm="12"
      >
        <!-- Ajouter un paramètre (Seulement pour le rôle 'admin')-->
        <v-col
          class="text-right ml-4"
          cols="12"
        >
          <v-btn
            color="success"
            @click="addItem"
          >
            {{ $t('Add') }}
            <v-icon
              right
              dark
            >
              mdi-plus-circle-outline
            </v-icon>
          </v-btn>
        </v-col>

        <v-data-table
          :headers="tableHeaders"
          :items="table"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :search="search"
          item-key="id"
          @page-count="pageCount = $event"
        >
          <!-- Search-->
          <template #top>
            <v-text-field
              v-model="search"
              :label="$t('search')"
              class="mx-4"
            />
          </template>

          <!-- Type-->
          <template
            slot="item.type"
            slot-scope="props"
          >
            <span>{{ $t(`adminClients.type.${props.item.type}`) }}</span>
          </template>

          <!-- Rapport-->
          <template
            slot="item.rapport"
            slot-scope="props"
          >
            <v-icon
              v-if="props.item.rapport"
              color="primary"
            >
              mdi-check
            </v-icon>
          </template>

          <!-- Approbation Commande-->
          <template
            slot="item.approbationCommande"
            slot-scope="props"
          >
            <v-icon
              v-if="props.item.approbationCommande"
              color="primary"
            >
              mdi-check
            </v-icon>
          </template>

          <!-- Action-->
          <template
            slot="item.action"
            slot-scope="props"
          >
            <!-- Edit-->
            <v-btn
              fab
              dark
              x-small
              color="success"
              :title="$t('Edit')"
              @click="editItem(props.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>

            <!--  Delete (Seulement pour le rôle 'admin')-->
            <v-btn
              v-if="isRoleAdmin"
              class="ml-2"
              fab
              dark
              x-small
              color="red"
              :title="$t('Delete')"
              @click="deleteItem(props.item.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
          />
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                v-model="itemsPerPage"
                :items="itemsPage"
                :label="$t('table.Items_per_page')"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </base-material-card>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="submitForm">
        <validation-observer
          ref="obs"
          v-slot="{ handleSubmit }"
        >
          <form>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ submitType === 'edit' ? $t('Edit') : $t('Add') }} {{ $t('a') }} {{ $t('sidebar.adminClients') }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>

            <!-- Type (Activé si le paramètre parameterGestionGroup est 3 (UBR ou Group))-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                Type
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Type"
                  rules="required"
                >
                  <v-radio-group
                    v-model="submitForm.type"
                    :disabled="parameterGestionGroup !== '3'"
                    :error-messages="errors"
                    :success="valid"
                    required
                    @change="changeType"
                  >
                    <v-radio
                      label="UBR"
                      :value="1"
                    />

                    <v-radio
                      :label="$t('Group')"
                      :value="2"
                    />
                  </v-radio-group>
                </validation-provider>
              </v-col>
            </v-row>

            <!-- Approbation des commandes (UBR seulement)-->
            <v-row
              v-if="submitForm.type === 1"
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('adminClients.Order_approval') }}
              </v-col>

              <v-col cols="8">
                <v-checkbox
                  v-model="submitForm.approbationCommande"
                />
              </v-col>
            </v-row>

            <!-- Voir les rapports des contrats-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('adminClients.Contracts_report') }}
              </v-col>

              <v-col cols="8">
                <v-checkbox
                  v-model="submitForm.rapport"
                  :disabled="submitForm.type === 2"
                />
              </v-col>
            </v-row>

            <!-- Code de facturation UBR (type 1)-->
            <v-row
              v-if="submitForm.type === 1"
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('contract.Billing_code') }}
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('contract.Billing_code')"
                  rules="required"
                >
                  <v-text-field
                    v-model="submitForm.code_facturation"
                    :label="$t('adminClients.Code_facturation_message')"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!-- Group (type 2)-->
            <v-row
              v-if="submitForm.type === 2"
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                {{ $t('Group') }}
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  :name="$t('Group')"
                  rules="required"
                >
                  <v-select
                    v-model="submitForm.GroupeId"
                    :items="groups"
                    item-text="name"
                    item-value="id"
                    persistent-hint
                    single-line
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <!-- Client-->
            <v-row
              align="center"
              dense
            >
              <v-col
                class="text-right text-body-1 grey--text mr-4"
                cols="2"
              >
                Client
              </v-col>

              <v-col cols="8">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="client"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="clientSearch.client"
                    :items="clientSearch.entries"
                    :loading="clientSearch.isLoading"
                    :search-input.sync="clientSearch.search"
                    hide-no-data
                    hide-selected
                    item-text="firstNameName"
                    item-value="id"
                    :placeholder="$t('Start_typing_Search')"
                    append-icon="mdi-account"
                    return-object
                    :error-messages="errors"
                    :success="valid"
                    required
                    @keypress.enter="$event.preventDefault()"
                  >
                    <template #selection="data">
                      {{ data.item.firstNameName }}
                    </template>
                    <template #item="data">
                      {{ data.item.firstNameName }}
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
            </v-row>

            <div class="pa-3 text-center">
              <!--  Bouton annuler-->
              <v-btn
                color="error"
                @click="dialog = false"
              >
                {{ $t('Cancel') }}
              </v-btn>
              <!--  Bouton submit-->
              <v-btn
                color="success"
                class="ml-3"
                @click="handleSubmit(submit)"
              >
                {{ $t('Validate') }}
              </v-btn>
            </div>
          </form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  // Services
  import AdminClientsService from '@/services/01Cell/AdminClientsService'
  import GroupesService from '@/services/01Cell/GroupesService'
  import ClientsService from '@/services/01Cell/ClientsService'
  // Mixins
  import { roles } from '@/mixins/roles'
  // Other
  import { mapGetters } from 'vuex'
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'
  import Swal from 'sweetalert2'

  export default {
    name: 'OperationParameters',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [roles],
    data () {
      return {
        search: '',
        submitForm: {
          type: null,
          approbationCommande: false,
          rapport: false,
          code_facturation: null,
          GroupeId: null,
          ClientId: null,
        },
        submitType: null,
        table: [],
        tableHeaders: [
          { text: 'Type', value: 'type', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Code', value: 'Groupe.code', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('contract.Billing_code'), value: 'code_facturation', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('adminClients.Contracts_report'), value: 'rapport', sortable: true, class: 'text-h4 primary--text' },
          { text: this.$i18n.t('adminClients.Order_approval'), value: 'approbationCommande', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Client', value: 'Client.username', sortable: true, class: 'text-h4 primary--text' },
          { text: 'Action', value: 'action', sortable: false, align: 'end', class: 'text-h4 primary--text', width: 150 },
        ],
        page: 1,
        pageCount: 0,
        itemsPerPage: 50,
        itemsPage: [5, 10, 25, 50, 100],
        dialog: false,
        groups: [],
        clientSearch: {
          client: null,
          entries: [],
          isLoading: false,
          search: null,
        },
      }
    },

    computed: {
      ...mapGetters('parameters', {
        parameterGestionGroup: 'getGestionGroup',
      }),
    },

    watch: {
      'clientSearch.search': {
        // This will let Vue know to look inside the array
        deep: true,
        // We have to move our method to a handler field
        async handler () {
          if (!this.clientSearch.search) return
          if (this.clientSearch.search.length < 3) return
          // Items have already been requested
          if (this.clientSearch.isLoading) return

          this.clientSearch.isLoading = true

          try {
            const clients = await ClientsService.search(this.clientSearch.search, false)

            this.clientSearch.entries = clients.map(entry => {
              // L'object 'firstNameName' c'est pour l'affichage des nom dans le v-autocomplete
              entry.firstNameName = `[ ${entry.username} ] ${entry.prenom} ${entry.nom}`
              // console.log(Object.assign({}, entry, { Description }))
              return entry
            })
            this.clientSearch.isLoading = false
          } catch (error) {
            console.log(error)
          }
        },
      },
      'submitForm.type': {
        // This will let Vue know to look inside the array
        deep: true,
        // We have to move our method to a handler field
        async handler () {
          if (this.submitForm.type === 2) this.submitForm.rapport = true
        },
      },
    },

    async mounted () {
      this.getAdminClients()
    },

    methods: {
      async getAdminClients () {
        // Start Spinner
        this.$loading.show()
        try {
          this.table = await AdminClientsService.list()
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async getGroups () {
        try {
          if (!this.groups.length) {
            const groups = await GroupesService.list()
            this.groups = groups.map(item => {
              item.name = `${item.code} (${item.description})`
              return item
            })
          }

          // Show dialog
          this.dialog = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
        }
      },
      async editItem (item) {
        // this.clientSearch.client = this.client
        // this.clientSearch.client.firstNameName = `[ ${this.client.username} ] ${this.client.prenom} ${this.client.nom}`
        // // On le mets dans les entrée du client
        // this.clientSearch.entries.push(this.clientSearch.client)

        // Start Spinner
        this.$loading.show()
        try {
          // Edit
          this.submitType = 'edit'
          // Paramètre
          this.submitForm = await AdminClientsService.view(item.id)

          // Le client
          this.clientSearch.client = this.submitForm.Client
          this.clientSearch.client.firstNameName = `[ ${this.submitForm.Client.username} ] ${this.submitForm.Client.prenom} ${this.submitForm.Client.nom}`
          // On le mets dans les entrée du client
          this.clientSearch.entries.push(this.clientSearch.client)

          // Récupération des groupes
          this.getGroups()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      addItem () {
        // Reset Client
        this.clientSearch.client = null

        // Start Spinner
        this.$loading.show()
        // Ajout
        this.submitType = 'add'
        // Type
        let type
        if (this.parameterGestionGroup === '1') type = 1 // UBR
        if (this.parameterGestionGroup === '2') type = 2 // Group
        if (this.parameterGestionGroup === '3') type = null // UBR ou Group

        // Reset du submit
        this.submitForm = {
          type: type,
          approbationCommande: false,
          rapport: false,
          code_facturation: null,
          GroupeId: null,
          ClientId: null,
        }

        // Récupération des groupes
        this.getGroups()
      },
      deleteItem (id) {
        // SweetAlert
        Swal.fire({
          icon: 'warning',
          title: this.$i18n.t('Are_you_sure'),
          text: `${this.$i18n.t('adminClients.delete')} ?`,
          showCancelButton: true,
          confirmButtonColor: '#DD6B55',
          confirmButtonText: this.$i18n.t('Yes_delete_it'),
          cancelButtonText: this.$i18n.t('Cancel'),
        }).then(async (result) => {
          if (result.value) {
            // Start Loader
            this.$loading.show()
            try {
              await AdminClientsService.remove(id)

              this.getAdminClients()
            } catch (err) {
              // Stop Spinner
              this.$loading.hide()
            }
          }
        })
      },
      changeType () {
        this.submitForm.code_facturation = null
        this.submitForm.GroupeId = null
      },
      async submit () {
        // Hide dialog
        this.dialog = false

        // Start Spinner
        this.$loading.show()

        // Le client
        this.submitForm.ClientId = this.clientSearch.client.id

        try {
          if (this.submitType === 'edit') {
            await AdminClientsService.update(this.submitForm, this.submitForm.id)
          } else {
            await AdminClientsService.save(this.submitForm)
          }

          this.getAdminClients()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
    },
  }
</script>
