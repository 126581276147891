
export const stripe = {
    data () {
    },

    computed: {
    },

    methods: {
        manipulation (data) {
            if (data.data.length) {
              // Pour une requête Stripe list
              this.dataTransactions.startingAfter = data.data[data.data.length - 1].id
            }

            this.dataTransactions.data = [...this.dataTransactions.data, ...data.data]
            this.dataTransactions.has_more = data.has_more
            // Pour une requête Stripe search
            this.dataTransactions.next_page = data.next_page
          },
    },
}
