import api from '@/services/Api'

export default {
    list () { // Liste tout
        return api().get('/adminClients')
    },
    save (adminClient) { // Création d'une nouvelle entrée
        return api().post('/adminClients', adminClient)
    },
    view (adminClientId) { // Voir une entrée spécifique
        return api().get(`/adminClients/${adminClientId}`)
    },
    update (adminClient, adminClientId) { // Mise à jour d'une entrée spécifique
        return api().put(`/adminClients/${adminClientId}`, adminClient)
    },
    remove (adminClientId) { // Suppression d'une entrée spécifique
        return api().delete(`/adminClients/${adminClientId}`)
    },
    query (item, filter) { // Recherche spécifique
        return api().get(`/adminClients/query/${encodeURIComponent(item)}?champ=${filter}`)
    },
}
