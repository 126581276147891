<template>
  <v-container
    id="customers-list"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-account-multiple"
      :title="`${$t('Customer')}s`"
      class="px-5 py-3"
    >
      <!-- Recherche (Seulement pour la route 'payments-transaction-list')-->
      <div
        class="d-flex align-center justify-center"
      >
        <v-slide-group
          v-model="model"
          class="pa-4"
          center-active
          show-arrows
        >
          <v-slide-item
            v-for="(item, i) in searchCard"
            :key="i"
            v-slot="{ active, toggle }"
          >
            <v-card
              class="ma-4"
              rounded="lg"
              outlined
              :style="{ border: active ? '1px solid #c7254e' : null }"
              height="100"
              width="170"
              @click="toggle"
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
                <span :class="active ? 'primary--text' : 'grey--text'">{{ item.text }}</span>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </div>

      <validation-observer
        ref="obs"
      >
        <form>
          <!-- Recherche par Client -->
          <v-row
            v-if="model === 1"
            align="center"
            justify="center"
            dense
          >
            <v-col
              cols="10"
              class="text-body-1 grey--text"
            >
              <validation-provider
                v-slot="{ errors, valid }"
                name="Client"
                :rules="{ required: true, min: searchSelected.customer.minLength }"
              >
                <v-text-field
                  v-model="searchSelected.customer.selected"
                  class="mt-3"
                  :label="$t('Payment.placeHolder.customer')"
                  :error-messages="errors"
                  :success="valid"
                  required
                  @keydown.enter.prevent
                  @keydown.enter="submitSearch"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </form>
      </validation-observer>

      <v-row>
        <v-col cols="12">
          <v-client-table
            ref="table"
            :data="dataTransactions.data"
            :columns="table.columns"
            :options="table.options"
          >
            <!--  Action-->
            <template
              slot="action"
              slot-scope="props"
            >
              <!--  Transactions-->
              <v-btn
                class="ml-2"
                fab
                light
                x-small
                title="Transactions"
                color="green"
                @click="getTransactions(props.row.id)"
              >
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>

            <!--  ClientId-->
            <template
              slot="clientId"
              slot-scope="props"
            >
              <a
                v-if="props.row.clientId"
                href="#"
                @click.prevent="getClientId(props.row.clientId)"
              >{{ props.row.clientId }}</a>
              <span v-else>{{ $t('Payment.No_client') }}</span>
            </template>
          </v-client-table>
        </v-col>

        <!-- Load more -->
        <v-col
          v-if="dataTransactions.data.length"
          cols="12"
          class="text-center"
        >
          <div>
            <v-btn
              :disabled="!dataTransactions.has_more"
              color="primary"
              @click="loadMore"
            >
              {{ $t('Load_more') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </base-material-card>

    <!-- Edition du client -->
    <v-dialog
      v-model="dialogClient"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialogClient = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  large
                >
                  mdi-account
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <h3>Client</h3>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <!--  Edition d'un client-->
        <div class="mt-5">
          <client-create-edit
            :create-edit-info="createEditInfo"
            :client-id="clientId"
            @create-update-client="createUpdateClient"
            @cancel-edit-client="cancelEditClient"
          />
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  // Components
  import ClientCreateEdit from '@/components/Client/ClientCreateEdit'
  // Service
  import StripeCustomerService from '@/services/STRIPE/StripeCustomerService'
  import ClientsService from '@/services/01Cell/ClientsService'
  // Mixins
  import { stripe } from '@/mixins/Payment/stripe'
  // Other
  import {
    ValidationObserver,
    ValidationProvider,
  } from 'vee-validate'

  export default {
    name: 'PaymentsCustomerList',
    components: {
      ClientCreateEdit,
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [stripe],
    data () {
      return {
        model: 0,
        periods: [],
        dialogClient: false,
        clientId: null,
        createEditInfo: {
          icon: 'mdi-pencil',
          title: `${this.$i18n.t('Edit')}`,
        },
        searchCard: [
          { text: this.$i18n.t('Payment.searchCustomers.All_customers') },
          { text: this.$i18n.t('Payment.searchCustomers.Search_by_name') },
        ],
        searchSelected: {
          customer: {
            selected: null,
            minLength: 1,
          },
        },
        dataTransactions: {
          data: [],
          has_more: false,
          startingAfter: null,
          next_page: null,
        },
        table: {
          columns: ['action', 'id', 'name', 'email', 'clientId'],
          options: {
            sortable: ['id', 'name', 'email', 'clientId'],
            filterByColumn: true,
            filterable: ['id', 'name', 'email', 'clientId'],
            pagination: { chunk: 5 },
            perPage: 25,
            perPageValues: [10, 25, 50, 100],
            showChildRowToggler: false, // Si il y a des child row, sert pour cacher la colonne + pour afficher le child row. On utilise la fonction 'tableRowClick' plus bas
            texts: {
              count: this.$i18n.t('vueTables.texts.Showing') + ' {from} ' + this.$i18n.t('vueTables.texts.to') + ' {to} ' + this.$i18n.t('vueTables.texts.of') + ' {count} ' + this.$i18n.t('vueTables.texts.Records').toLowerCase() + '|{count} ' + this.$i18n.t('vueTables.texts.Records').toLowerCase() + '|' + this.$i18n.t('vueTables.texts.One_record'),
              first: this.$i18n.t('vueTables.texts.First'),
              last: this.$i18n.t('vueTables.texts.Last'),
              filter: this.$i18n.t('vueTables.texts.Filter') + ':',
              filterPlaceholder: this.$i18n.t('vueTables.texts.Search_query'),
              limit: this.$i18n.t('vueTables.texts.Records'),
              page: 'Page:',
              noResults: this.$i18n.t('vueTables.texts.No_matching_records'),
              filterBy: this.$i18n.t('vueTables.texts.Filter_by') + ' {column}',
              loading: this.$i18n.t('vueTables.texts.Loading'),
              defaultOption: this.$i18n.t('vueTables.texts.Select') + ' {column}',
              columns: this.$i18n.t('vueTables.texts.Columns'),
            },
            headings: {
              name: this.$i18n.t('Name'),
              email: this.$i18n.t('client.Email'),
            },
            columnsClasses: {
              action: 'VueTables-width-action-column text-left', // Voir dans le fichier 'style.scss'
            },
            sortIcon: {
              is: 'fa-sort',
              base: 'fas',
              up: 'fa-sort-up',
              down: 'fa-sort-down',
            },
          },
        },
      }
    },

    computed: {
    },

    watch: {
      model (value) {
        this.submitSearch()
      },
    },

    mounted () {
      this.getListCustomers()
    },

    methods: {
      reset () {
        // Reset
        this.dataTransactions.data = []
        this.dataTransactions.has_more = false
        this.dataTransactions.startingAfter = null
        this.dataTransactions.next_page = null
      },
      submitSearch () {
        this.reset()
        this.search()
      },
      search () {
        if (this.model === 0) this.getListCustomers()
        if (this.model === 1) this.getSearchCustomersByName()
      },
      async getListCustomers () {
        // Start Spinner
        this.$loading.show()
        try {
          const customers = await StripeCustomerService.list(this.dataTransactions.startingAfter)
          this.manipulation(customers)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      async getSearchCustomersByName () {
        if (!this.searchSelected.customer.selected) return
        // Min 3 charactères
        if (this.searchSelected.customer.selected.length < this.searchSelected.customer.minLength) return

        // Start Spinner
        this.$loading.show()
        try {
          const customers = await StripeCustomerService.searchByCustomer(this.searchSelected.customer.selected, this.dataTransactions.next_page)
          console.log(customers)
          this.manipulation(customers)
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      loadMore () {
        this.search()
      },
      getClientId (clientId) {
        this.clientId = clientId
        this.dialogClient = true
      },
      async createUpdateClient (event) {
        // Start Spinner
        this.$loading.show()
        try {
          await ClientsService.update(event, event.id)

          // Montre la confirmation
          this.$notifier.show({ message: this.$i18n.t('client.snackBar.client_updated_successfully'), color: 'success', icon: 'mdi-checkbox-marked-circle', timeout: 5000, position: { top: true, center: true, left: false, right: false } })

          // Close dialog
          this.dialogClient = false
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      cancelEditClient () {
        // Close dialog
        this.dialogClient = false
      },
      getTransactions (customerId) {
        this.$router.push({ name: 'payments-customer-transaction-list', params: { id: customerId } })
      },
    },
  }
</script>
