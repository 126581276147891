const menu = [
  // Opération
  {
    group: '/operation',
    icon: 'mdi-cogs',
    title: 'operation',
    roles: ['admin', 'niveau0'],
    children: [
      {
        title: 'client',
        roles: ['admin', 'niveau0'],
        to: 'client',
      },
      {
        title: 'contract',
        roles: ['admin', 'niveau0'],
        to: 'contrat',
      },
      {
        title: 'billing',
        roles: ['admin', 'niveau0'],
        to: 'facture',
      },
      {
        title: 'order',
        roles: ['admin', 'niveau0'],
        to: 'commande',
      },
      {
        title: 'tools',
        roles: ['admin', 'niveau0'],
        to: 'tools',
      },
    ],
  },
  // Autre
  {
    group: '/other',
    icon: 'mdi-database-settings',
    title: 'other',
    roles: ['admin'],
    children: [
      {
        title: 'parameters',
        roles: ['admin'],
        to: 'parameters',
      },
      {
        title: 'messages',
        roles: ['admin'],
        to: 'messages',
      },
      {
        title: 'list',
        roles: ['admin'],
        to: 'list',
      },
      {
        title: 'toggles',
        roles: ['admin'],
        to: 'toggles',
      },
      {
        title: 'adminClients',
        roles: ['admin'],
        to: 'adminclients',
      },
      {
        title: 'groups',
        roles: ['admin'],
        to: 'groupes',
      },
      {
        title: 'devices',
        roles: ['admin', 'niveau0'],
        to: 'devices',
      },
    ],
  },
  // Payment
  {
    group: '/payments',
    icon: 'mdi-currency-usd',
    title: 'payments',
    roles: ['admin'],
    children: [
      {
        title: 'payments_account',
        roles: ['admin'],
        to: 'account',
      },
      {
        title: 'payments_customers',
        roles: ['admin'],
        to: 'customer',
      },
      {
        title: 'payments_transactions',
        roles: ['admin'],
        to: 'transaction',
      },
    ],
  },
  // Dashboard (Chart)
  {
    icon: 'mdi-chart-areaspline',
    title: 'dashboard',
    roles: ['admin', 'niveau0'],
    to: '/dashboard',
  },
]

export default menu
