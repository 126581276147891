<template>
  <v-container
    id="payments-list"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-currency-usd"
      :title="`${$t('Payment.My_payments').toUpperCase()}`"
      class="px-5 py-3"
    >
      <payments-list-stripe-component
        ref="paymentsListStripeComponent"
        :data-transactions="dataTransactions"
        :per-page="25"
        @update-list-transactions="updateListTransactions"
        @load-more="loadMore"
      />
    </base-material-card>
  </v-container>
</template>

<script>
  // Component
  import PaymentsListStripeComponent from '@/components/Payments/Stripe/PaymentsListComponent'
  // Service
  import StripeChargeService from '@/services/STRIPE/StripeChargeService'

  export default {
    name: 'ProfilePaymentsList',
    components: {
      PaymentsListStripeComponent,
    },
    data () {
      return {
        dialogPaiementList: false,
        dataTransactions: {
          data: [],
          has_more: false,
          next_page: null,
        },
        customerId: null,
      }
    },

    computed: {
    },

    mounted () {
      this.getListTransactions()
    },

    methods: {
      async getListTransactions () {
        // Start Spinner
        this.$loading.show()
        try {
          const charges = await StripeChargeService.queryCustomerId(this.dataTransactions.next_page)
          this.dataTransactions.data = [...this.dataTransactions.data, ...charges.data]
          this.dataTransactions.has_more = charges.has_more
          this.dataTransactions.next_page = charges.next_page

          // Show dialog
          this.dialogPaiementList = true
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          console.log(error)
          // Stop Spinner
          this.$loading.hide()
        }
      },
      loadMore () {
        this.getListTransactions()
      },
      updateListTransactions () {
        this.getListTransactions()
      },
    },
  }
</script>
