import api from '@/services/Api'

export default {
    save () {
        return api().post('stripe/accounts')
    },
    update (account) {
        return api().put('stripe/accounts', account)
    },
    view () {
        return api().get('/stripe/accounts')
    },
    createAccountLink (account) {
        return api().post('stripe/createAccountLink', account)
    },
}
