<template>
  <div id="checkout">
    <!-- Stripe Checkout embedded form will be mounted here -->
  </div>
</template>

<script>
  // Service
  import StripeCardService from '@/services/STRIPE/StripeCardService'
  // Other
  import { loadStripe } from '@stripe/stripe-js'

  export default {
    name: 'StripeFacturePaiement',
    props: {
      paymentMethod: Object,
      client: Object,
    },
    data () {
      return {
        stripe: null,
        checkout: null,
        language: this.$i18n.t('Language'),
      }
    },
    async mounted () {
      try {
        // Start Spinner
        this.$loading.show()
        // Récupération de l'api public
        const { apiPublic, stripeAccount } = await StripeCardService.getApiPublic()
        console.log(apiPublic)

        // Charger Stripe.js
        this.stripe = await loadStripe(apiPublic, { stripeAccount }) // Remplacez par votre clé publique Stripe
        // Initialiser le Checkout
        await this.initializeCheckout()
      } catch (error) {
        console.log(error)
        // Stop Spinner
        this.$loading.hide()
      }
    },

    methods: {
      async initializeCheckout () {
        try {
          const baseUrl = window.location.origin
          const fetchClientSecret = async () => {
            const response = await StripeCardService.createSession({
              session: {
                customer: this.client.paiement_id,
                currency: 'cad',
                billing_address_collection: 'Sera défini dans le backend avec le paremètre 31', // AVS
                locale: this.client.langue === 'fr' ? 'fr-CA' : 'en',
                mode: 'setup',
                ui_mode: 'embedded',
                payment_method_types: ['card'], // Inclut Apple Pay
                metadata: {
                  clientId: this.client.id,
                  state: this.paymentMethod.state, // add (ajout d'une carte), edit (modification d'une carte)
                  updatePaymentMethodId: this.paymentMethod.updatePaymentMethodId, // Si modification (edit). On passe l'ancien paiement inscription
                },
                return_url: `${baseUrl}/profile?stripeSessionId={CHECKOUT_SESSION_ID}`,
                // return_url: 'https://example.com/success?session_id={CHECKOUT_SESSION_ID}'
                // success_url: 'https://example.com/success?session_id={CHECKOUT_SESSION_ID}',
              },
              idempotencyKey: `nouvelleCarte${Date.now()}${this.client.id}`,
            })
            return response.client_secret
          }

          // Initialize Checkout
          this.checkout = await this.stripe.initEmbeddedCheckout({
            fetchClientSecret,
          })

          // Monter le formulaire dans l'élément HTML
          this.checkout.mount('#checkout')
          // Stop Spinner
          this.$loading.hide()
        } catch (error) {
          // Refresh la page
          this.$router.go()
          console.error('Erreur lors de l\'initialisation de Stripe Checkout:', error)
        }
      },
    },
  }
</script>
