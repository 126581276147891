<template>
  <v-container
    id="payments-view"
    fluid
    tag="section"
  >
    <base-material-card
      inline
      icon="mdi-currency-usd"
      :title="`${$t('Payment.My_payments').toUpperCase()}`"
      class="px-5 py-3"
    >
      sadsd
    </base-material-card>
  </v-container>
</template>

<script>

  export default {
    name: 'PaymentsList',

    data () {
      return {
      }
    },

    computed: {
    },

    methods: {
    },
  }
</script>
